import { useOverviewAuthStore } from '@libero/hooks/store/useOverviewAuthStore';
import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    path: 'login',
    name: 'auth.login',
    component: () => import('./views/pages/Login.vue'),
    meta: {
      title: 'Inloggen',
    },
  },
  {
    path: 'verify',
    name: 'auth.verify',
    component: () => import('./views/pages/Verify.vue'),
    meta: {
      title: 'Login controle',
    },
  },
  {
    path: 'terms',
    name: 'auth.terms',
    component: () => import('./views/pages/Terms.vue'),
    beforeEnter: (to, from, next): void => {
      const { authenticated } = useOverviewAuthStore();

      if (!authenticated) {
        return next({ name: 'auth.login' });
      }

      next();
    },
    meta: {
      title: 'Privacybeleid',
      hideTitle: true,
    },
  },
];
