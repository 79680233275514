import { jsonApi } from '@libero/utilities/api-client';

import type { LoginResponse, ProviderLoginResponse } from './types';

const overviewAuth = jsonApi('api/overview/auth');

const providerLogin = async (): Promise<ProviderLoginResponse> => {
  return overviewAuth.get('microsoft/redirect');
};

const providerVerify = async (search: string): Promise<LoginResponse> => {
  return overviewAuth.post(`microsoft/callback${search}`);
};

export const overviewAuthApi = {
  providerLogin,
  providerVerify,
};
