import AppLayout from '@libero/cityview-overview/modules/app/views/layouts/AppLayout/AppLayout.vue';
import AuthLayout from '@libero/cityview-overview/modules/app/views/layouts/AuthLayout/AuthLayout.vue';
import { routes as authRoutes } from '@libero/cityview-overview/modules/auth/routes';
import { useOverviewAuthStore } from '@libero/hooks/store/useOverviewAuthStore';
import { i18n } from '@libero/i18n/index';
import { defaultRouterOptions } from '@libero/utilities/routing';
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';

const routesImports = import.meta.glob('./modules/!(auth)/routes.ts', {
  eager: true,
}) as Record<string, { routes: RouteRecordRaw[] }>;

const routes = Object.values(routesImports)
  .map((routes) => routes.routes)
  .flat();

const protectedRoute = () => {
  const authStore = useOverviewAuthStore();

  if (!authStore.authenticated) {
    return { name: 'auth.login' };
  }
};

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/auth',
      component: AuthLayout,
      children: authRoutes,
    },
    {
      path: '/',
      component: AppLayout,
      beforeEnter: [protectedRoute],
      children: [
        ...routes,
        {
          path: '/',
          redirect: { name: 'tenant.index' },
        },
      ],
    },
  ],
  ...defaultRouterOptions,
});

router.afterEach((to) => {
  document.title = `${to.meta.title} - ${i18n.global.t('cityview-overview')}`;
});
